export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  uniqueId: {
    label: 'Identificador',
    type: String
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    module: 'devices',
    autocomplete: false
  },
  phone: {
    label: 'Teléfono',
    type: String,
    optional: true
  },
  model: {
    label: 'Modelo',
    type: String,
    optional: true
  },
  contact: {
    label: 'Contacto',
    type: String,
    optional: true
  },
  category: {
    label: 'Categoría',
    type: String,
    optional: true,
    options: [{
      id: 'municipal',
      name: 'municipal',
    }, {
      id: 'municipalp',
      name: 'municipalp',
    }, {
      id: 'estatalp',
      name: 'estatalp',
    }, {
      id: 'ministerial',
      name: 'ministerial',
    }, {
      id: 'ministerialp',
      name: 'ministerialp',
    }, {
      id: 'ambulancia',
      name: 'ambulancia',
    }, {
      id: 'car',
      name: 'car',
    }, {
      id: 'arrow',
      name: 'arrow',
    }, {
      id: 'pcivil',
      name: 'pcivil',
    }, {
      id: 'bronce',
      name: 'bronce',
    }]
  },
  fuelType: {
    label: 'Tipo de combustible',
    type: String,
    options: [{
      id: 'magna',
      name: 'Magna'
    }, {
      id: 'premium',
      name: 'Premium'
    }, {
      id: 'diesel',
      name: 'Diesel'
    }, {
      id: 'gas',
      name: 'Gas'
    }],
    optional: true
  },
  kmPerLiter: {
    label: 'Kilometros por litro',
    type: Number,
    optional: true
  },
  isDvr: {
    label: 'Es DVR?',
    type: Boolean,
    onChange: (val, fields) => {
      fields.channel01.setVisibility(val)
      fields.channel02.setVisibility(val)
      fields.channel03.setVisibility(val)
      fields.channel04.setVisibility(val)
    }
  },
  channel01: {
    label: 'Channel 01',
    type: Boolean
  },
  channel02: {
    label: 'Channel 02',
    type: Boolean
  },
  channel03: {
    label: 'Channel 03',
    type: Boolean
  },
  channel04: {
    label: 'Channel 04',
    type: Boolean
  },
  disabled: {
    label: 'Deshabilitado',
    type: Boolean,
    optional: true
  },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true
  }
};
