import Dialog from '@/components/dialog';
import Form from '@/components/form';
import ApiService from '@/util/api.service';

const geofences = async ({ device }) => {
  window.VMA.loading(true);
  try {
    const _devices = await ApiService({
      url: '/geofences',
      method: 'get',
    });

    const _permissions = await ApiService({
      url: '/geofences',
      method: 'get',
      params: {
        deviceId: device.id,
      },
    });
    const form = new Form({
      schema: _devices.reduce(
        (acc, geofence) => ({
          ...acc,
          [geofence.id]: {
            label: geofence.name,
            type: Boolean,
            changeOnLoad: false,
            onChange: async (val, fields) => {
              if (val) {
                ApiService({
                  url: '/permissions',
                  method: 'post',
                  data: { deviceId: device.id, geofenceId: geofence.id },
                })
                  .then(() => {})
                  .catch((err) => {
                    console.error(err);
                    window.VMA.showError({ title: `Error al activar "${geofence.name}"` });
                  });
              } else {
                ApiService({
                  url: '/permissions',
                  method: 'delete',
                  data: { deviceId: device.id, geofenceId: geofence.id },
                })
                  .then(() => {})
                  .catch((err) => {
                    console.error(err);
                    window.VMA.showError({ title: `Error al activar "${geofence.name}"` });
                  });
              }
            },
          },
        }),
        {}
      ),
      item: _permissions.reduce(
        (acc, permission) => ({
          ...acc,
          [permission.id]: true,
        }),
        {}
      ),
    });
    const dialog = new Dialog({
      title: 'Geocercas',
      subtitle: device.name,
    });
    dialog.open();
    dialog.append(form);
  } catch (err) {
    window.VMA.showError('Error al cargar las geocercas');
    console.error(err);
  } finally {
    window.VMA.loading(false);
  }
};

const notifications = async ({ device }) => {
  window.VMA.loading(true);
  try {
    const _notificationsTypes = await ApiService({
      url: '/notifications',
      method: 'get',
    });

    const _notifications = await ApiService({
      url: '/notifications',
      method: 'get',
      params: {
        deviceId: device.id,
      },
    });

    const types = {
      geofenceEnter: 'Entrada a Geocerca',
      geofenceExit: 'Salida de Geocerca',
      ignitionOn: 'Encendido',
      ignitionOff: 'Apagado',
      deviceMoving: 'Movimiento',
      deviceStopped: 'Detenido',
      deviceOnline: 'Online',
    };

    const notificationsForm = new Form({
      schema: _notificationsTypes.reduce(
        (acc, notification) => ({
          ...acc,
          [notification.id]: {
            label: `${types[notification.type]}`,
            type: Boolean,
            changeOnLoad: false,
            onChange: async (val, fields) => {
              if (val) {
                ApiService({
                  url: '/permissions',
                  method: 'post',
                  data: { deviceId: device.id, notificationId: notification.id },
                })
                  .then(() => {})
                  .catch((err) => {
                    console.error(err);
                    window.VMA.showError({ title: `Error al activar "${notification.name}"` });
                  });
              } else {
                ApiService({
                  url: '/permissions',
                  method: 'delete',
                  data: { deviceId: device.id, notificationId: notification.id },
                })
                  .then(() => {})
                  .catch((err) => {
                    console.error(err);
                    window.VMA.showError({ title: `Error al activar "${notification.name}"` });
                  });
              }
            },
          },
        }),
        {}
      ),
      item: _notifications.reduce(
        (acc, notification) => ({
          ...acc,
          [notification.id]: true,
        }),
        {}
      ),
    });

    const dialog = new Dialog({
      title: 'Notificaciones',
      subtitle: device.name,
    });
    dialog.open();
    dialog.append(notificationsForm);
  } catch (err) {
    window.VMA.showError('Error al cargar las Notificaciones');
    console.error(err);
  } finally {
    window.VMA.loading(false);
  }
};

export default {
  geofences,
  notifications,
};
